<template>
  <v-card  max-width="400" min-height="350">
    <v-toolbar
        dark
        color="primary"
        elevation="0"
    >
      <v-btn icon v-if="showBack" @click="onBack">
        <v-icon>
          mdi-chevron-left
        </v-icon>
      </v-btn>
      <v-toolbar-title><span class="card__title--white" style="color: #ffffff;">{{ title }}</span></v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="onClose">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-form
        ref="form"
        v-model="valid"
        @submit.prevent="onSubmit"
    >
    <v-container fluid class="pt-5" v-if="!showBack">
      <v-row dense>
        <v-col>
          <v-btn color="primary" outlined block height="120" @click="onText">
            <v-icon>mdi-account-group</v-icon>
            Text Jammers
          </v-btn>
        </v-col>
        <v-col>
          <v-btn color="primary" outlined block height="120" @click="onEmail">
            <v-icon>mdi-email-outline</v-icon>
            Email
          </v-btn>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <v-btn color="primary" outlined block height="120" @click="onLink">
            Link
            <v-icon>mdi-link-variant</v-icon>
          </v-btn>
        </v-col>
        <v-col>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid class="pa-10" v-if="model.target==='text'">
      <v-row>
        <v-textarea
            disabled="disabled"
            label="Message"
            outlined
            v-model="model.message"
        />
      </v-row>
      <v-row>
        <v-btn block color="primary" @click="onSubmit">Send Text</v-btn>
      </v-row>
    </v-container>
    <v-container fluid class="pa-10" v-if="model.target === 'email'">

        <v-row>
          <TextField
              class="mb-lg-10 mb-5"
              :placeholder="$t('signUpPage.emailAddress')"
              v-model="model.email"
              :rules="rules.email"
              type="email"
              required
          />
        </v-row>
        <v-row>
          <v-textarea
              label="Message"
              outlined
              type="email"
              v-model="model.message"
          />
        </v-row>
        <v-row>
          <v-btn block color="primary" @click="onSubmit">Send Email</v-btn>
        </v-row>
    </v-container>
    <v-container fluid class="pa-10" v-if="model.target === 'link'">
      <v-row>
        <v-textarea
            ref="txtUrl"
            v-model="model.url"
            :disabled="linkDisabled"
            v-on:focus="$event.target.select()"
        />
      </v-row>
      <v-row>
        <v-btn block color="primary" @click="onCopy">Copy link</v-btn>
      </v-row>
    </v-container>
    </v-form>
  </v-card>
</template>

<script>
import TextField from '@/components/TextField';

export default {
  name: "Share",
  components: {
    TextField
  },
  props: {
    jamId: {
      type: String,
      default: ''
    },
    bookId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showBack: false,
      title: 'Share your jambook',
      target: 'none',
      linkDisabled: false,
      model: {
        message: '',
        email: '',
        target: '',
        url: ''
      },
      valid: true,
      rules: {
        email: [
          v => !!v || 'E-mail is required',
          v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ]
      }
    }
  },
  methods: {
    onClose() {
      this.showBack = false;
      this.model.target = '';
      this.$emit('close');
    },
    onBack() {
      this.title = 'share your jam';
      this.model.target = 'none';
      this.showBack = false;
      this.model.message = '';
    },
    onText() {
      this.title = 'Share - Text Jammers';
      this.showBack = true;
      this.model.target = 'text';
      this.model.message = this.$t('jammers.bookReady')
    },
    onEmail() {
      this.title = 'Share - Email';
      this.showBack = true;
      this.model.target = 'email';
      this.model.message = '';
      this.model.email = '';
    },
    onLink() {
      this.title = 'Share - Link';
      this.showBack = true;
      this.model.target = 'link';
    },
    onSubmit() {
      if (this.$refs.form.validate()) {
        console.log(this.model);
        this.$emit('submit', this.model);
        this.showBack = false;
        this.model.target = '';
      }
    },
    onCopy() {
      this.$refs.txtUrl.focus();
      this.linkDisabled = false;
      window.document.execCommand('copy');
      this.$emit('submit', this.model);
      this.showBack = false;
      this.model.target = '';
    },
    setUrl() {
      this.model.url = window.location.href.replace('jams', 'editor') + `/${this.jamId}/${this.bookId}`;
    }
  },
  created() {
    this.setUrl();
  },
  watch: {
    jamId: function(newVal) {
      this.setUrl(newVal);
    },
    bookId: function(newVal) {
      this.setUrl(newVal);
    }
  },
}

</script>
<template>
  <v-container class="px-0 ma-0 pb-0" fluid>
    <v-container class="pa-0 mb-0">
      <v-row class="d-flex align-center justify-center header">
        <v-col cols="auto">
          <h1 class="header__title" >{{  $t('jamList.header') }}</h1>
        </v-col>
      </v-row>
    </v-container>
    <v-row class="d-flex justify-center mb-15 ma-10">
      <v-col
          class="flex-column"
          lg="3"
          md="6"
          xs="12"
          sm="12"
          v-for="(jam, index) in jamList" :key="jam.id"
      >
        <v-hover
            v-slot="{ hover }"
        >
        <v-card :elevation="hover ? 12 : 0" class="mx-auto my-2" max-width="335" min-height="430">
          <v-img :src="jam.coverUrl ? jam.coverUrl : jam.logo" class="align-end ma-0 pa-0" height="267" />
          <v-card-title class="mt-0 pt-0">
            <v-text-field
                dense
                label="Name"
                density="compact"
                variant="solo"
                append-icon="mdi-check-circle-outline"
                single-line
                hide-details
                v-if="editMode === jam._id"
                v-model="editTitle"
                @click:append="onSaveTitle"
                @keydown.enter="onSaveTitle"
                @keydown.esc="editMode=''"
                autofocus
            ></v-text-field>
            <span
                v-if="editMode !== jam._id"
                @click="onEditName(jam._id, jam.name)"
            >{{ jam.name }}</span>
          </v-card-title>
          <v-card-subtitle class="pb-0">
            {{ formatPhone(jam.phoneNumber) }}
          </v-card-subtitle>
          <v-card-actions class="justify-end pr-5" v-if="hover">
            <v-btn @click="onCreate(jam._id)" v-if="!jam.bookId && jam.status === 'complete'">Create Book</v-btn>
            <v-btn color="primary" @click="onEdit(jam._id, jam.bookId)" v-if="jam.bookId">
              Edit
              <v-icon
                  right
                  dark
              >
                mdi-pencil-outline
              </v-icon>
            </v-btn>
            <v-btn @click="onOrder(jam._id, jam.bookId)" v-if="jam.bookId">
              Order
              <v-icon
                  right
                  dark
              >
                mdi-credit-card-outline
              </v-icon>
            </v-btn>
            <v-btn @click="onShare(index)" v-if="jam.bookId">
              Share
              <v-icon
                  right
                  dark
              >
                mdi-export-variant
              </v-icon>
            </v-btn>
            <v-btn @click="handleCardClick(jam._id)" v-if="jam.status === 'created' ||
                jam.status === 'active' ||
                jam.status === 'setup'"
            >{{ actionButtonLabel(jam) }}</v-btn>
            <v-btn v-if="jam.status === 'active'"
                   @click="onEndJamConfirm(jam._id)"
            >
              End Jam
            </v-btn>
          </v-card-actions>
          <v-card-subtitle v-if="hover">
            <a @click="handleCardClick(jam._id)"
               v-if="jam.status !== 'active' &&
               jam.status !== 'setup' &&
               jam.status !== 'created'"
               class="pr-6"
            >Edit Jam</a>
            <a v-if="currentUser.type === 'admin'" @click="onDeleteConfirm(jam._id)">Delete Jam</a>
          </v-card-subtitle>
        </v-card>
        </v-hover>
      </v-col>
    </v-row>
    <v-container>
      <v-btn type="submit" @click="handleNewClick" class="btn btn--pattern mb-15" block depressed height="120">
        <span class="btn__text--white">Create</span>
      </v-btn>
    </v-container>
    <v-snackbar
        :color="notify.color"
        elevation="24"
        min-width="none"
        height="50"
        v-model="notify.visible">
      <div class="snack-text">
        <!--        <v-icon large class="pr-3">mdi-check-circle</v-icon>-->
        <v-icon large class="pr-3" v-if="notify.icon">{{  notify.icon }}</v-icon>
        {{ notify.text }}
      </div>
    </v-snackbar>
    <v-dialog v-model="dialogConfirmDelete"
        max-width="450"
    >
      <v-card>
        <v-card-title>Jam Delete Confirm </v-card-title>
        <v-card-text>
          Deleting the jam named <strong>{{ selected.name }}</strong> will remove all prompts, all jammers, all
          responses and your jam phone number.
          <v-switch refs="togAssets" label="Yes, I want to delete everything" v-model="toDelete.all" />
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn @click="dialogConfirmDelete=false">Cancel</v-btn>
          <v-btn color="red" @click="onDelete" :disabled="!toDelete.all">
            <span style="color: #fff">Delete</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogShare"
      persistent
      max-width="400"
    >
      <Share
          @close="dialogShare=false"
          @submit="onShareSubmit"
          :jamId="shareJamId"
          :bookId="shareBookId"
      />
    </v-dialog>
    <v-dialog v-model="progress.visible" max-width="450" persistent>
      <ProgressDialog
          :text="progress.text"
      />
    </v-dialog>
    <v-dialog v-model="dialogEnd" max-width="450">
      <v-card>
        <v-card-title>End Jam Confirm</v-card-title>
        <v-card-text>
          Ending your jam will remove all future prompts and set your jam status to complete. Your jam will then be
          ready to create your book. However, this action cannot be undone.
          <v-switch refs="togEnd" label="Yes, I want to end my jam" v-model="togEnd" />
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn @click="dialogEnd=false">Cancel</v-btn>
          <v-btn @click="onEndJam" color="primary" :disabled="!togEnd">End Jam</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
// import { createNew } from '@/services/jamService';
import { mutationNames } from '@/store/jamStore'
import Http from '@/api/Http';
import { getFormattedPhone } from '@/lib';
import { ProgressDialog } from '@/components/dialogs';
import Share from '@/components/Share.vue';
import { getterNames } from '@/store/global';

export default {
  name: 'Jams',
  components: {
    ProgressDialog,
    Share
  },
  data() {
    return {
      showBannerText: false,
      dialogConfirmDelete: false,
      dialogShare: false,
      dialogEnd: false,
      editMode: '',
      editTitle: '',
      shareParams: {},
      shareJamId: '',
      shareBookId: '',
      togEnd: false,
      toEndId: false,
      progress: {
        visible: false,
        text: ''
      },
      toDelete: {
        id: false,
        jam: false,
        all: false
      },
      notify: {
        visible: false,
        icon: null,
        text: null,
        color: null
      },
      jamList: [],
      api: Http
          .fail(this.fail)
          .use('data')
    }
  },
  computed: {
    travelLogo() {
      return require('@/assets/Luggage.svg');
    },
    journalLogo() {
      return require('@/assets/Notebook.svg');
    },
    eventLogo() {
      return require('@/assets/Party.svg');
    },
    personLogo() {
      return require('@/assets/Crown.svg');
    },
    selected() {
      const lst = this.jamList.filter(jam => (jam._id === this.toDelete.id));
      if (lst.length) {
        return lst[0];
      } else {
        return {};
      }
    },
    currentUser() {
      return this.$store.getters[getterNames.currentUser];
    }
  },
  async mounted() {
    await this.loadJams();
  },
  methods: {
    actionButtonLabel(jam) {
      switch (jam.status) {
        case 'active':
          return 'Edit Jam';
        case 'setup':
          return 'Setup Jam';
        case 'created':
          return 'Start Jam'
      }
    },
    onSaveTitle() {
      this.api.put(`/jams/${this.editMode}/update`, { name: this.editTitle })
          .then(() => {
            this.jamList.forEach(j => {
              if (j._id === this.editMode) {
                j.name = this.editTitle;
              }
            });
            this.editMode = '';
            this.$toast('Jam has been renamed');
          });
    },
    onEditName(jamId, name) {
      this.editMode = jamId;
      this.editTitle = name;
    },
    onCreate(jamId) {
      this.progress = {
        visible: true,
        text: 'Creating book...'
      }
      this.api.post(`/jams/${jamId}/createBook`)
          .then((book) => {
            this.progress.text = 'Loading book...';
            window.location.href = `/editor/${jamId}/${book._id}`;
            this.progress.visible = false;
          })
          .catch(err => {
            this.progress.visible = false;
            this.$toast(err.response.data.error ? err.response.data.error : err.message, {
              color: 'error',
              icon: 'mdi-alert-circle-outline'
            });
          });
    },
    async loadJams() {
      this.api.get('/jams')
          .then(jams => {
            this.jamList = jams.map(jm => {
              jm.logo = this.travelLogo;
              if (jm.group === 'journal') {
                jm.logo = this.journalLogo;
              } else if (jm.group === 'event') {
                jm.logo = this.eventLogo;
              } else if (jm.group === 'person') {
                jm.logo = this.personLogo;
              }
              return jm;
            });
            this.$store.commit(mutationNames.SAVE_LIST, this.jamList);
            this.$store.commit(mutationNames.SELECT, null);
            if (this.jamList.length === 0) {
              this.$router.push('/create');
            }
          })
          .catch(err => {
            if (err.response.status === 401) {
              this.$router.push('/login');
            }
          });
    },
    onEverythingToggle(evt) {
      this.toDelete.assets = evt;
      this.toDelete.number = evt;
    },
    onDeleteConfirm(id) {
      this.toDelete.all = false;
      this.toDelete.id = id;
      this.dialogConfirmDelete = true;
    },
    async onDelete() {
      this.dialogConfirmDelete = false;
      this.progress.visible = true;
      try {
        if (this.toDelete.all) {
          this.progress.text= 'Deleting the jam';
          await this.api.delete(`/jams/${this.toDelete.id}`)
              .catch(err => {
                this.this.progress.visible = false;
                this.loadJams();
                if (err.response.data.error) {
                  throw new Error(err.response.data.error);
                } else {
                  throw err;
                }
              });
        }
        this.progress.visible = false;
        this.$toast('Jam deleted');
        this.loadJams();
      } catch (err) {
        this.$toast(err.message, {
          color: 'error',
          icon: 'mdi-alert-circle-outline'
        });
      }
    },
    formatPhone(phone) {
      return getFormattedPhone(phone);
    },
    getFormattedPhone,
    fail(err) {
      console.log('fail', err);
    },
    onEdit(jamId, bookId) {
      window.location.href = `/editor/${jamId}/${bookId}`;
    },
    onOrder(jamId, bookId) {
      window.location.href = `/editor/${jamId}/${bookId}?order=true`;
    },
    handleCardClick(id) {
      if (id === 'new') {
        this.$store.commit(mutationNames.SELECT, 'new');
        this.$router.push('/jam/create/1');
      } else {
        this.$store.commit(mutationNames.SELECT, id);
        let jam = this.jamList.filter(jm => {
          return jm._id === id;
        })[0];

        if (jam.status !== 'setup') {
          this.$router.push('/jam/' + id);
        } else {
          this.$router.push('/jam/' + id + '/create/1');
        }
      }
    },
    async handleNewClick() {
      this.$router.push('/create');
    },
    onIntersect(entries) {
      let vm = this
      setTimeout(function() {
        vm.showBannerText = entries[0].isIntersecting;
      }, 500);
    },
    onShare(jamIndex) {
      this.dialogShare = true;
      this.shareParams = {
        jamId: this.jamList[jamIndex]._id,
        bookId: this.jamList[jamIndex].bookId
      };
      this.shareJamId = this.jamList[jamIndex]._id;
      this.shareBookId = this.jamList[jamIndex].bookId;
    },
    async onShareSubmit(mod) {
      try {
        if (mod.target === 'link') {
          this.$toast('Link copied to clipboard');
          this.dialogShare = false;
        } else {
          let notifyText = '';
          if (mod.target === 'text') {
            this.progress.text = 'Sending text to Jammers';
            notifyText = 'Jammers have been texted';
          } else if (mod.target === 'email') {
            this.progress.text = 'Sending email';
            notifyText = 'Jambook has been shared via email.'
          }
          this.progress.visible = true;
          await this.api.post(`/books/${this.shareParams.bookId}/share`, mod)
              .then(() => {
                this.progress.visible = false;
                this.$toast(notifyText);
                this.dialogShare = false;
              })
              .catch(err => {
                this.progress.visible = false;
                if (err.response.data.error) {
                  throw new Error(err.response.data.error);
                } else {
                  throw err;
                }
              });
        }
      } catch (err) {
        this.dialogShare = false;
        this.$toast(err.message + ' other thing', {
          color: 'error',
          icon: 'mdi-alert-circle-outline'
        });
      }
    },
    async onShareJammers() {
      try {
        this.progress.text = 'Sending text to Jammers'
        this.progress.visible = true;
        await this.api.post(`/books/${this.shareParams.bookId}/share`, {
          target: 'jammers',
          message: this.$t('jammers.bookReady')
        })
            .then(() => {
              this.progress.visible = false;
              this.$toast('Jammers have been notified');
            })
            .catch(err => {
              this.progress.visible = false;
              if (err.response.data.error) {
                throw new Error(err.response.data.error);
              } else {
                throw err;
              }
            });
      } catch (err) {
        this.$toast(err.message, {
          color: 'error',
          icon: 'mdi-alert-circle-outline'
        });
      }
    },
    onEndJamConfirm(jamId) {
      this.toEndId = jamId;
      this.dialogEnd = true;
    },
    onEndJam() {
      this.dialogEnd = false;
      this.togEnd = false;
      try {
        this.progress.visible = true;
        this.api.post(`/jams/${this.toEndId}/end`)
            .then(() => {
              this.progress.visible = false;
              this.$toast('Jam has ended.');
              this.$router.push('/jam/' + this.toEndId);
            })
            .catch(err => {
              this.progress.visible = false;
              if (err.response.data.error) {
                throw new Error(err.response.data.error);
              } else {
                throw err;
              }
            })
      } catch (err) {
        this.$toast(err.message, {
          color: 'error',
          icon: 'mdi-alert-circle-outline'
        });
      }
    }
  }
}
</script>

<style scoped>
.animation-min-height {
  min-height: 2rem;
}
.v-tab--active.tab__text:after {
  content: "";
  background: var(--v-text-base);
  border-radius: 1rem;
  position: absolute;
  bottom: -2px;
  width: 50%;
  height: 4px;
  left: 50%;
  transform: translate(-50%, -50%);
}
.header {
  margin-bottom: 4rem;
}
.header__title {
  font-size: 5rem;
  color: var(--v-primary-base);
}
.banner >>> .v-banner__content {
  padding-top: .5rem;
}
.banner {
  text-align: center;
}
.banner__text {
  font-size: 2.75rem;
  color: white;
  font-weight: bold;
}
.tab__text {
  font-size: 2.5rem;
  font-weight: bold;
  letter-spacing: 0;
  margin-right: 6.25rem;
  padding-bottom: 10px;
}
.left-container {
  margin-right: 4rem;
}
.theme--light.v-tabs >>> .v-tab:not(.v-tab--active) {
  font-weight: 400;
  color: var(--v-text-base);
}

@media only screen and (max-width: 1264px) {
  .header {
    margin-top: 0;
    margin-bottom: 2rem;
  }
  .banner__text {
    font-size: 1.5rem;
  }
  .banner >>> .v-banner__wrapper {
    padding: .5rem
  }
  .tab__text {
    font-size: 1.5rem;
    margin-right: 3.5rem;
  }
}
@media only screen and (min-width: 601px) and (max-width: 1264px) {
  .header__title {
    font-size: 4.6rem;
  }
}
@media only screen and (max-width: 600px) {
  .header__title {
    font-size: 2rem;
  }
}
.btn {
  font-size: 6.4rem;
  background: url(../assets/Start_Preserving_Background.svg);
  background-size: cover;
}
</style>
